<template>
  <div>
    <router-view v-show='this.$route.matched.length==3'></router-view>
    <page-header-layout v-show='this.$route.matched.length==2'>
      <div class='main-page-content'>
        <h2>平台数据（订单导入记录）</h2>
        <div class='flex-sb' style='margin-bottom: 8px'>
          <el-form size='mini' inline :model='searchCondition'>
            <el-form-item label='月份'>
              <el-date-picker
                v-model='searchCondition.month'
                type='month'
                value-format='yyyy-MM-dd'
                format='yyyy年MM月'
                placeholder='选择月' style='width: 140px'>
              </el-date-picker>
            </el-form-item>
            <el-form-item label='平台'>
              <el-select v-model='searchCondition.platform_code' :placeholder='`可选平台`'
                         style='width: 400px' multiple
                         clearable>
                <el-option v-for='(item,key) in options'
                           :label='item.label' :value='item.value'
                           :key='key'>
                  {{ item.label }}<span> ({{ item.count || 0 }})</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='导入状态'>
              <el-select v-model='searchCondition.state' clearable>
                <el-option v-for='(label,val) in stateMap' :key='`state_${val}`' :value='val' :label='label'>
                  {{ label }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' @click.native.stop='handleQuery'>查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-link icon='el-icon-files' type='primary' @click='jumpToTradeList'>订单数据浏览
              </el-link>
            </el-form-item>
            <!--            <el-form-item>-->
            <!--              <el-link icon='el-icon-list' type='default' @click='historyImport'>导入记录-->
            <!--              </el-link>-->
            <!--            </el-form-item>-->
          </el-form>
          <div style='margin-bottom: 10px'>
            <el-button icon='el-icon-upload' type='warning' @click='handleImport'>导入</el-button>
          </div>
        </div>
        <div class='default-table' v-loading='loadingStatus'
             element-loading-text='加载中……'
             element-loading-spinner='el-icon-loading'>
          <el-table :data='dataList' border>
            <el-table-column label='序号' type='index' align='center'></el-table-column>
            <el-table-column label='月份' prop='month' align='center'>
              <template slot-scope='{row}'>{{ $utils.parseTime(row.month, '{y}年{m}月') }}</template>
            </el-table-column>
            <el-table-column label='平台' prop='platform_name' align='center'></el-table-column>
            <el-table-column label='文件名称' prop='file_name' min-width='200' header-align='center'
                             align='left'>
              <template slot-scope='{row}'>
                <el-link @click='handleShowExcel(row)'>{{ row.file_name }}</el-link>
                <div class='remark'>{{ row.remark || '--' }}</div>
              </template>
            </el-table-column>
            <el-table-column label='状态' prop='state' align='center' width='100'>
              <template slot-scope='{row}'>
                <span :class='`state_${row.state}`'>
              {{ handleMap(row.state) }}
              </span>
              </template>
            </el-table-column>
            <el-table-column label='进度' prop='state' align='left' header-align='center' width='160'>
              <template slot-scope='{row}'>
                <span :title='`已导入数量 / 表格行数 / 已匹配红人数`'>
                已导入：{{ $utils.numberFormat(row.row_offset) }} 行 <br />
                表格行：{{ $utils.numberFormat(row.row_count) }} 行 <br />
                已匹配：{{ $utils.numberFormat(row.matched_num) }} 行 <br />
              </span>
              </template>
            </el-table-column>
            <el-table-column label='耗时' prop='used_time' align='left' header-align='center'>
              <template slot-scope='{row}'>
                <span>导入：{{ secondsToReadable(row.used_time / 1000) }}</span><br />
                <span>匹配：{{ secondsToReadable(row.matched_time / 1000) }}</span><br />
                <span>效率：每秒 {{ row.used_time > 0 ? $utils.numberFormat(1000 * row.row_offset / row.used_time, 2) : '-'
                  }} 行</span>
              </template>
            </el-table-column>
            <el-table-column label='操作' align='center'>
              <template slot-scope='{row}'>
                <div class='flex-evenly'>
                  <div v-if='[1].indexOf(row.state)>-1'>
                    <span style='color: red'><i class='el-icon-loading'></i>载入中，请稍后...</span>
                  </div>
                  <div v-else class='flex-evenly'>
                    <!--                  <el-link v-loading='loadingCommit' :disabled='loadingCommit' type='primary'-->
                    <!--                           @click.native.stop='handleStore(row)'-->
                    <!--                           v-if='[-2,1].indexOf(row.state)>-1'-->
                    <!--                           icon='el-icon-coin'>载入-->
                    <!--                  </el-link>-->
                    <!--                  <el-link v-loading='loadingCommit' :disabled='loadingCommit' type='primary'-->
                    <!--                           @click.native.stop='handleMatch(row)'-->
                    <!--                           v-if='[2].indexOf(row.state)>-1'-->
                    <!--                           icon='el-icon-s-check'>匹配-->
                    <!--                  </el-link>-->
                    <el-link type='primary'
                             @click.native.stop='handleShowExcel(row)'
                             icon='el-icon-s-data'>预览
                    </el-link>
                  </div>

                  <el-dropdown trigger='click' @command='(e,a)=>handleCommand(row,e,a)'>
                    <!--                  <el-dropdown trigger="click" @command="handleCommand(row,$event)">-->
                    <span class='el-dropdown-link'>
                      更多<i class='el-icon-arrow-down el-icon--right'></i>
                    </span>
                    <el-dropdown-menu slot='dropdown'>
                      <el-dropdown-item icon='el-icon-refresh-left' command='cancel'>
                        撤回
                      </el-dropdown-item>
                      <el-dropdown-item icon='el-icon-coin' command='store'>
                        载入
                      </el-dropdown-item>
                      <el-dropdown-item icon='el-icon-paperclip' command='match'>
                        匹配
                      </el-dropdown-item>
                      <el-dropdown-item icon='el-icon-delete' command='delete'>
                        删除
                      </el-dropdown-item>

                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div>
            <m-pagination v-show='total>0' :total.sync='total' :limit.sync='pagingData.page_size'
                          :page.sync='pagingData.current_page'
                          @pagination='getList'></m-pagination>
          </div>
        </div>
      </div>
    </page-header-layout>

    <!--    导入Excel文件并保存-->
    <el-dialog :visible.sync='open'>
      <div>
        <ImportAndCreate ref='refImportAndCreate' @close='open=false' @saved='handleQuery'></ImportAndCreate>
      </div>
    </el-dialog>

    <!--    表格数据预览-->
    <el-dialog :visible.sync='fileOpen' fullscreen>
      <div style='padding: 5px'>
        <ExcelPageTable :key='`ExcelPageTable_${currentRow.id}`' :file-info='currentRow'
                        ref='refPreviewExcelPageTable' />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TradeImport from '@/pages/trade/import/TradeImport'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import MlPageTable from '@/components/common/MlPageTable'
import PlatformSelect from '@/pages/lb/components/PlatformSelect'
import ImportAndCreate from '@/pages/trade/import/ImportAndCreate'
import {
  cancelTradeImported,
  deleteTradeImported,
  getTradeImportList, matchTradeImported,
  optionsTradeImported,
  storeTradeImported
} from '@/api/trade_import'
import ExcelPageTable from '@/pages/trade/import/ExcelPageTable'

export default {
  name: 'index',
  components: { ExcelPageTable, ImportAndCreate, PlatformSelect, MlPageTable, PageHeaderLayout, TradeImport },
  data() {
    return {
      loadingCommit: false,
      loadingStatus: false,
      open: false,
      fileOpen: false,
      currentRow: { id: null, name: null },
      searchCondition: { month: null, platform_code: [], state: null },
      options: {},
      pagingData: { page_size: 10, current_page: 1 },
      total: 0,
      tableName: 'trade_import_list',
      dataList: [],
      columns: [
        { hide: false, prop: 'tid', label: '订单ID', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'goods_id', label: '商品ID', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'goods_title', label: '商品名称', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'pay_at', label: '付款时间', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'kol_nickname', label: '达人昵称', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'kol_id', label: '达人抖音号', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'trade_status', label: '订单状态', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'trade_source', label: '下单来源', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'flow_source', label: '流量来源', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'flow_sub', label: '流量细分渠道', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'file_md5', label: '导入文件md5', width: 100, sortable: false, fixed: false }
      ],
      stateMap: {}
      // 订单id	商品id	商品名称	订单状态	流量来源	超时未结算原因

    }
  },
  created() {
    this.init()
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    '$route.matched': function(n, o) {
      if (n.length === 2) {
        this.init()
      }
    }
  },
  methods: {
    init() {
      // console.log(this.$route.matched.length === 2)
      //避免详情路由页面打开时候加载父页面的网络请求
      if (this.$route.matched.length === 2) {
        this.loadOptions()
        this.handleQuery()
      }
    },
    handleMap(state) {
      return this.stateMap[state] || state
    },
    handleImport() {
      this.open = true
      // this.$router.push({ name: 'trade-import' })
      this.$nextTick(() => {
        this.$refs['refImportAndCreate'].reset()
      })
    },
    jumpToTradeList() {
      // this.$router.push({ name: 'trade-import' })

      const { href } = this.$router.resolve({
        name: 'trade-list'
      })
      window.open(href, '_blank')
    },
    historyImport() {
      // this.$router.push({ name: 'trade-import' })

      const { href } = this.$router.resolve({
        name: 'trade-import'
      })
      window.open(href, '_blank')
    },
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()
    },
    handleSearchCondition() {
      let cond = {}
      Object.keys(this.searchCondition).forEach((k) => {
        if (this.searchCondition[k] !== null && this.searchCondition[k] !== '') {
          cond[k] = this.searchCondition[k]
        }
      })

      return cond
    },
    async getList() {
      this.loadingStatus = true
      let param = this.handleSearchCondition()
      Object.assign(param, this.pagingData)
      let { list, page_info, state_map } = await getTradeImportList(param)
      this.dataList = list || []
      this.pagingData.current_page = page_info.current_page || 1
      this.pagingData.page_size = page_info.page_size || 10
      this.total = page_info.total || 0
      this.loadingStatus = false
      this.stateMap = state_map || {}
    },
    async handleCancel(row) {
      if (this.loadingCommit) {
        return false
      }
      this.$confirm('确认要撤回导入的数据？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.loadingCommit = true
        let data = await cancelTradeImported(row.id)
        if (data) {
          await this.getList()
          this.$notify.success('已撤回')
        }
        this.loadingCommit = false
      }).catch(() => {
        this.loadingCommit = false
        this.$message({
          type: 'info',
          message: '已取消撤回'
        })
      })

    },
    async loadOptions() {
      // 加载可选平台，即系统里已录入的平台
      let { platform } = await optionsTradeImported()
      this.options = platform || []
    },
    handleShowExcel(row) {
      this.currentRow = { id: row.file_id, name: row.file_name }
      this.fileOpen = true
      this.$nextTick(() => {
        this.$refs['refPreviewExcelPageTable'].init()
      })

    },
    async handleMatch(row) {
      if (this.loadingCommit) {
        return false
      }
      this.$confirm('确认要执行【匹配系统红人】操作，可重复执行？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.loadingCommit = true
        let data = await matchTradeImported(row.id)
        if (data) {
          this.$notify.success('已发起红人匹配，请注意匹配进度')
          setTimeout(async () => {
            this.loadingCommit = false
            await this.getList()
          }, 1000)
        }

      }).catch(() => {
        this.loadingCommit = false
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      })
    },
    async handleStore(row) {
      if (this.loadingCommit) {
        return false
      }
      this.$confirm('确认要二次载入表格的数据？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.loadingCommit = true
        let data = await storeTradeImported(row.id)
        if (data) {
          this.$notify.success('已发起载入请求,可刷新列查看载入进度')
          setTimeout(async () => {
            this.loadingCommit = false
            await this.getList()
          }, 100)
        }

      }).catch(() => {
        this.loadingCommit = false
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      })
    },
    handleDelete(row) {
      this.$confirm('确认要删除导入的表格？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.loadingCommit = true
        let data = await deleteTradeImported(row.id)
        if (data) {
          await this.getList()
          this.$notify.success('已删除')
        }
        this.loadingCommit = false
      }).catch(() => {
        this.loadingCommit = false
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    //秒数可读性
    secondsToReadable(seconds) {
      const days = Math.floor(seconds / (3600 * 24))
      const hours = Math.floor((seconds % (3600 * 24)) / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const secs = (seconds % 60).toFixed(3)

      const parts = []
      if (days) parts.push(`${days} 天`)
      if (hours) parts.push(`${hours} 小时`)
      if (minutes) parts.push(`${minutes} 分钟`)
      if (secs || parts.length === 0) parts.push(`${secs} 秒`)

      return parts.join('  ')
    },
    //操作指令
    handleCommand(row, command) {
      switch (command) {
        case 'cancel':
          this.handleCancel(row)
          break
        case 'store':
          this.handleStore(row)
          break
        case 'match':
          this.handleMatch(row)
          break
        case 'delete':
          this.handleDelete(row)
          break
      }
      // this.$message('click on item ' + command)
    }
  }
}
</script>

<style scoped>
.state_-1 {
  color: #E6A23C;
}

.state_-2 {
  color: #F56C6C;
}

.state_1 {
  color: #409EFF;
}

.state_2 {
  color: #67C23A;
}

.remark {
  font-size: 0.9em;
  font-weight: lighter;
  padding-left: 10px;
}
</style>
